import {
  parseISO,
  format,
  parse,
} from 'date-fns';

const isUndefined = (value) => typeof value === 'undefined';

const isNullOrUndefined = (value) => value === null || isUndefined(value);

const pickBy = (items, objSource) => {
  if (typeof objSource === 'undefined') {
    return undefined;
  }
  const a = Object.entries(objSource).filter((x) => items.includes(x[0]));

  return Object.fromEntries(a);
};

const requiredFieldsUndefined = (...args) => args.filter(({ value }) => isUndefined(value)
|| value === null
|| value === '');

const isEmailValid = (email) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

const isString = (value) => typeof value === 'string';

const formatAndParse = (dateWithTimeZone) => {
  const formattedDate = format(dateWithTimeZone, 'MM/dd/yy');
  return parse(formattedDate, 'MM/dd/yy', new Date());
};

const formatISODate = (dateISO) => {
  const isoDate = parseISO(dateISO);
  const day = isoDate.getUTCDate();
  const month = isoDate.getUTCMonth();
  const year = isoDate.getUTCFullYear();

  return new Date(year, month, day);
};

const formatDate = (dateInput, inputFormat = 'MM/dd/yy') => {
  const isDateString = isString(dateInput);
  if (dateInput === null) {
    return undefined;
  }

  const convertedDate = !isDateString
    ? dateInput
    : formatISODate(dateInput);
  return format(convertedDate, inputFormat).toString();
};

export {
  isString,
  isUndefined,
  isNullOrUndefined,
  pickBy,
  requiredFieldsUndefined,
  isEmailValid,
  formatDate,
  formatISODate,
  formatAndParse,
};
