export const COUNTRY_ID = 244; // this might change once we populate production db
export const dummy = 'dummy';
export const localSkipStorageKey = 'hasOkoUserSkippedProfile';
export const CONFIRM_EDIT_COPY = 'Confirm Changes';
export const ADD_PRODUCT = 'Add New Product';
export const ADD_NEW_PRODUCT_TITLE = 'Add new product';
export const MODIFY_PRODUCT_TITLE = 'Modify product';

export const transactionPickForSummary = ['id', 'purchase_date', 'supplier_name', 'buyer_name',
  'product_name', 'price_per_unit', 'lead_time', 'inco_abbv', 'term_abbv', 'eta', 'etd',
  'total', 'quantity', 'status', 'unit', 'company_name', 'port_origin', 'port_discharge',
  'net_weight', 'requested_delivery_date_to_dest', 
];

const buyerPickForSummary = ['supplier_hq_address', 'supplier_country_name', 'supplier_state_name',
  'supplier_city_name', 'supplier_zipcode'];

const supplierPickForSummary = ['buyer_hq_address', 'buyer_country_name', 'buyer_state_name',
  'buyer_city_name', 'buyer_zipcode'];

export const companyPickForSummary = (type) => (type === 'buyer' ? buyerPickForSummary : supplierPickForSummary);

export const documentsPicks = ['status', 'legacy_id', 'transaction_id', 'proforma_invoice', 'purchase_order',
  'isf', 'commercial_invoice', 'packing_list', 'technical_data_sheet', 'certificate_of_analysis', 'safety_data_sheet',
  'certificate_of_origin', 'shipping_insurance', 'dangerous_goods_declaration',
  'delivery_order', 'notice_of_arrival', 'form_7501', 'bill_of_lading'];

export const mainDocumentAvailable = {
  bill_of_lading: 'Bill of Lading',
  proof_of_delivery: 'Proof of Delivery',
};

export const chartStackedColors = [
  '#D7816A',
  '#93B5C6',
  '#DDEDAA',
  '#F0CF65',
  '#DDFBD2',
  '#A69658',
  '#D9B26F',
  '#716969',
  '#D65780',
  '#EE9480',
  '#F37748',
];

export const routeLineJsonTemplate = (routes) => ({
  type: 'geojson',
  data: {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: routes,
    },
  },
});

export const templateShipJson = ({
  shipCoordinates,
  eta,
  origin,
  destination,

}) => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: shipCoordinates,
      },
      properties: {
        id: 'shipEvents',
        title: 'Ship Events',
        description: 'Properties for ship popup',
        origin,
        destination,
        eta,
      },
    },
  ],
});

export const templateOriginDestination = ({ origin, destination }) => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: origin,
      },
      properties: {
        id: 'origin',
        title: 'Container 1',
        description: 'Mumbai 1',
        pol: 'Port Houston - Texas, USA',
        postPod: 'Indira Dock - Mumbai, India',
        vessel: {
          name: 'MAERSK ENSHI',
          imo: 9502946,
          call_sign: '3FIL',
          mmsi: 355288000,
          flag: 'PA',
        },
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: destination,
      },
      properties: {
        id: 'destination',
        title: 'Container 1',
        description: 'Mumbai 1',
        pol: 'Port Houston - Texas, USA',
        postPod: 'Indira Dock - Mumbai, India',
        vessel: {
          name: 'MAERSK ENSHI',
          imo: 9502946,
          call_sign: '3FIL',
          mmsi: 355288000,
          flag: 'PA',
        },
      },
    },
  ],
});
