import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useLocalStorage } from '@rehooks/local-storage';
import CircularProgress from '@mui/material/CircularProgress';
import classnames from 'classnames/bind';
import useStore from '../store/user';
import { isUndefined } from '../lib/common-helpers';
import { localSkipStorageKey } from '../constants/common';
import styles from './main/main.module.css';

const cx = classnames.bind(styles);

export default function Home() {
  const router = useRouter();
  const [hasUserSkippedProfileOnboarding] = useLocalStorage(localSkipStorageKey, false);
  const user = useStore((state) => state.user);
  const getSignedInUser = useStore((state) => state.getSignedInUser);

  const hasUserCreatedProfile = typeof user?.hasProfile !== 'undefined' && user?.hasProfile;

  useEffect(() => {
    async function fetchSignedInUser() {
      await getSignedInUser();
    }
    fetchSignedInUser();
  }, [getSignedInUser, user?.email]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (isUndefined(user?.email) && user?.hasFetchedFromSession) {
        router.push('/login');
      } else if (!isUndefined(user?.email) && hasUserCreatedProfile === false
      && !hasUserSkippedProfileOnboarding) {
        router.push('/onboarding');
      } else if ((!isUndefined(user?.isAuthorized) && !user?.isAuthorized)
      || !user?.isSessionValid) {
        router.push('/login');
      } else if (user?.hasProfile || hasUserSkippedProfileOnboarding) {
        router.push('/dashboard');
      }
    }, 800);

    return () => clearTimeout(timeOut);
  }, [
    user?.email,
    user?.isAuthorized,
    user?.hasProfile,
    user?.hasFetchedFromSession,
    user?.isSessionValid,
    hasUserCreatedProfile,
    router,
    hasUserSkippedProfileOnboarding,
  ]);

  return (
    <div className={cx('container')}>
      <CircularProgress
        size="4rem"
        className={cx('progress')}
        color="primary"
      />
    </div>
  );
}

export async function getStaticProps() {
  return {
    props: { revalidate: 10 },
  };
}
